<template lang="pug">
	div Reset Succesfully
</template>

<script>
export default {
	mounted() {
		localStorage.setItem('filter', '')
	},
}
</script>
